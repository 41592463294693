import logo from './logo.svg';
import './App.css';
import Body from './components/Body'

function App() {
  return (
   <Body />

  );
}

export default App;
